import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import {environment} from 'src/environments/environment';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  /*** Feature toggles */
  showDelhivery: boolean = environment.showDelhivery;
  constructor(public authService: AuthService, public router: Router) {

  }

  createNewSB(val, type, carrier) {
    if (carrier == 'DHL') {
      this.router.navigate(['/dhl_form/add'])
    }
    else if (carrier == 'Aramex') {
      this.router.navigate(['/aramex/add'])
    }
    else if (carrier == 'Fedex') {
      this.router.navigate(['/fedex/add'])
    }
    else if (carrier == "DHLexpress") {
      this.router.navigate(['/dhlexpress/add'])
    }
    else if (carrier == 'xpressbees') {
      this.router.navigate(['/xpressbees'])
    }
    else if (carrier == 'delhivery') {
      this.router.navigate(['/delhivery'])
    }
    else {
      this.router.navigate(['/bill/add'])
    }
  }


  ngOnInit() {

  }

}
