<div class="wrapper" style="margin-top: -2.5rem">

    <div style="background-image: url('assets/img/bg1.jpg');background-position: center; background-size: cover; height: 100vh;">
        <div class=" mask flex-center rgba-black-strong">
            <div class="container" style="margin-top:62px;min-height: 550px;">
                <div class="row">
                    <div class="col-md-12" style="margin: auto;">
                        <div class="row" style="margin-top: 10px">
                            <div class="col-md-6 mx-mn-height">
                                <div class="card waves-effect mx-mn-height"
                                    (click)="createNewSB(true, 1, 'India Post');">
                                    <div class="card-body btn btn-outline-pink">
                                        <img style="margin-right:30px;" src="assets/img/newLogo/India_grey.png" alt=""
                                            srcset="" style="height:100px; padding:15px">
                                        <h5 class="card-title text-center py-2">
                                            <a>Enter Data (India Post)</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div style="display: none" class="col-md-6 mx-mn-height"
                                *ngIf="authService.isExporterAdmin() || authService.isSuperAdmin()">
                                <div class="card waves-effect mx-mn-height" (click)="createNewSB(true, 1, 'DHL');">
                                    <div class="card-body btn btn-outline-info">
                                        <img style="margin-right:30px;" src="assets/img/newLogo/DHL_grey.png" alt=""
                                            srcset="" style="height:100px; padding:15px">
                                        <h5 class="card-title text-center py-2 ">
                                            <a>Enter Data (DHL)
                                            </a>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 mx-mn-height"
                                *ngIf="authService.isExporterAdmin() || authService.isSuperAdmin() ">
                                <div class="card waves-effect mx-mn-height"
                                    (click)="createNewSB(true, 1, 'xpressbees');">
                                    <div class="card-body btn btn-outline-grey">
                                        <img style="margin-right:30px;" src="assets/images/xpressbessLogo.png" alt=""
                                            srcset="" style="height: 100px; padding: 31px; margin: 1px;">
                                        <h5 class="card-title text-center py-2 ">
                                            <a>Enter Data (Xpressbees)
                                            </a>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="showDelhivery">
                                <div class="col-md-6 mx-mn-height" *ngIf="authService.isExporterAdmin() || authService.isSuperAdmin() ">
                                    <div class="card waves-effect mx-mn-height" (click)="createNewSB(true, 1, 'delhivery');">
                                        <div class="card-body btn btn-outline-grey">
                                            <img style="margin-right:30px;" src="assets/img/carrier_partners/Delhivery_Logo_for_PMB.png" alt=""
                                                srcset="" style="height: 100px; padding: 20px; margin: 1px;">
                                            <h5 class="card-title text-center py-2 ">
                                                <a>Enter Data (Delhivery)
                                                </a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="col-md-6 mx-mn-height"
                                *ngIf="authService.isExporterAdmin() || authService.isSuperAdmin()">
                                <div class="card waves-effect mx-mn-height" (click)="createNewSB(true, 1, 'Fedex');">
                                    <div class="card-body btn btn-outline-grey">
                                        <img style="margin-right:30px;" src="assets/img/newLogo/fedex1.png" alt=""
                                            srcset="" style="    height: 100px; padding: 27px; margin: 1px;">
                                        <h5 class="card-title text-center py-2 ">
                                            <a>Enter Data (FedEx)
                                            </a>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 mx-mn-height"
                                *ngIf="authService.isExporterAdmin() || authService.isSuperAdmin()">
                                <div class="card waves-effect mx-mn-height"
                                    (click)="createNewSB(true, 1, 'DHLexpress');">
                                    <div class="card-body btn btn-outline-grey">
                                        <img style="margin-right:30px;" src="assets/img/newLogo/DHL_EX.png" alt=""
                                            srcset="" style="    height: 100px; padding: 31px; margin: 1px;">
                                        <h5 class="card-title text-center py-2 ">
                                            <a>Enter Data (DHL Express)
                                            </a>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 mx-mn-height"
                                *ngIf="authService.isExporterAdmin() || authService.isSuperAdmin()">
                                <div class="card waves-effect mx-mn-height" (click)="createNewSB(true, 1, 'Aramex');">
                                    <div class="card-body btn btn-outline-grey">
                                        <img style="margin-right:30px;" src="assets/img/newLogo/aramex_grey.png" alt=""
                                            srcset="" style="height:100px; padding:15px">
                                        <h5 class="card-title text-center py-2 ">
                                            <a>Enter Data (Aramex)
                                            </a>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 mx-mn-height">
                                <div class="card waves-effect mx-mn-height" routerLink="/bulkupload">
                                    <div class="card-body btn btn-outline-success">
                                        <img style="margin-right:30px;" src="assets/img/excel.png" alt="" srcset=""
                                            style="height:100px">
                                        <h5 class="card-title text-center py-2 ">
                                            <a>Bulk Upload Data using Excel </a>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>