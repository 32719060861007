<div class="wrapper" style="    margin-bottom: 100px;">
    <div class="container" style="margin-top:5%">
        <div [ngClass]="({'col-md-6':!isCompare,'col-md-12':isCompare})" style="margin: auto">
            <div class="card p-3">
                <div class="card-body">
                    <h4 class="card-title text-center pink-text">Postage Calculator</h4>
                    <hr>
                    <div class="flex-row">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="blue-text">Select Country</label>

                                <!--  -->
                                <ng-multiselect-dropdown [placeholder]="'Select Country'" [settings]="settings"
                                    [data]="countries" [(ngModel)]="select" (onSelect)="onItemSelect()"
                                    (onDeSelect)="OnItemDeSelect($event)" (onDeSelectAll)="changeCountry()">
                                </ng-multiselect-dropdown>
                                <!--  -->

                                <!-- <ng-select class="custom" highlightColor="#9575cd" [(ngModel)]="select"
                                    [placeholder]="'Select Country'" filterPlaceholder="Select Country"
                                    (change)="onItemSelect()">
                                    <ng-option *ngFor="let c of countries" [value]="c?.id">{{c?.itemName}}</ng-option>
                                </ng-select> -->

                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="formGroupExampleInput" class="blue-text">Weight (gms)</label>
                                    <input type="text" style="height: 43px;" placeholder="Weight (Eg. 250)"
                                        (keypress)="restrictNumeric($event)" [(ngModel)]="maxWeight"
                                        class="form-control" id="formGroupExampleInput">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" style="height: 43px;" placeholder="Length (cm)"
                                    (keypress)="restrictNumeric($event)" [(ngModel)]="maxLength" class="form-control"
                                    id="maxLength">
                            </div>
                            <div class="col-md-4">
                                <input type="text" style="height: 43px;" placeholder="Width (cm)"
                                    (keypress)="restrictNumeric($event)" [(ngModel)]="maxWidth" class="form-control"
                                    id="maxWidth">
                            </div>
                            <div class="col-md-4">
                                <input type="text" style="height: 43px;" placeholder="Height (cm)"
                                    (keypress)="restrictNumeric($event)" [(ngModel)]="maxHeight" class="form-control"
                                    id="maxHeight">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="col-md-6 text-center" style="margin: auto">
                                    <button type="button" (click)="calculate()"
                                        [disabled]="select?._id=='0' || !(maxWeight) || maxWeight==0 || maxWeight=='0' || !(maxHeight) || maxHeight==0 || maxHeight=='0' || !(maxWidth) || maxWidth==0 || maxWidth=='0' || !(maxLength) || maxLength==0 || maxLength=='0'"
                                        class="btn btn-outline-primary mt-4">Calculate</button>
                                </div>
                            </div>
                        </div>
                        <ul class="list-group" *ngIf="!dataSuccess" style="margin-top: 30px">
                            <li class="list-group-item d-flex justify-content-between align-items-center" style="    background: #d83060;
                  color: #fff;" readonly>
                                INDIA POST
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"
                                *ngIf="(ems!='')">
                                EMS Speed Post
                                <span class="badge badge-primary badge-pill" *ngIf="ems?.length<=5">{{ems |
                                    currency:'INR':'code':'1.2-2'}}</span>
                                <span class="badge badge-primary badge-pill" *ngIf="ems?.length>5">{{ems}}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"
                                *ngIf="(air!='')">
                                International Air Parcel
                                <span class="badge badge-primary badge-pill" *ngIf="air?.length<=5">{{air |
                                    currency:'INR':'code':'1.2-2'}}</span>
                                <span class="badge badge-primary badge-pill" *ngIf="air?.length>5">{{air}}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"
                                *ngIf="(airServe!='')">
                                Small Packets (Air Service)
                                <a id="df" *ngIf="!(airServe=='Upto 2 Kg is supported Only')"
                                    data-tooltip="₹ 70 Registration Fee charges (already included in tariff)"
                                    style="position:absolute; display:none"><i class="fa fa-info-circle mr-2"></i></a>
                                <span id="454" class="badge badge-primary badge-pill"
                                    *ngIf="airServe?.length<=5">{{airServe | currency:'INR':'code':'1.2-2'}} </span>
                                <span id="454" class="badge badge-primary badge-pill"
                                    *ngIf="airServe?.length>5">{{airServe}} </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"
                                *ngIf="(trackedPackets!='')">
                                International Tracked Packets
                                <span class="badge badge-primary badge-pill"
                                    *ngIf="trackedPackets?.length<=5">{{trackedPackets |
                                    currency:'INR':'code':'1.2-2'}}</span>
                                <span class="badge badge-primary badge-pill"
                                    *ngIf="trackedPackets?.length>5">{{trackedPackets}}</span>
                            </li>
                        </ul>
                        <ul class="list-group" *ngIf="!dataError" style="margin-top: 30px">
                            <li class="list-group-item d-flex justify-content-between align-items-center" style="    background: #d83060;
                  color: #fff;" readonly>
                                INDIA POST
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center pink-text">
                                {{weightNotSupported}}
                            </li>
                        </ul>
                        <div class="col-md-12 text-center">
                            <button type="button" data-toggle="modal" data-target="#exampleModal"
                                style="background: linear-gradient(40deg,#ff6ec4,#7873f5) !important;"
                                class="btn btn-sm waves-effect waves-light">EMS Tariff Discount Structure</button>
                        </div>

                        <!-- <ul class="list-group" style="margin-top: 30px" *ngIf="dhlData?.length>0">
                  <li class="list-group-item d-flex justify-content-between align-items-center" style="background: #e5ac36;
                  color: #fff;" readonly>
                     DHL RATES
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let d of dhlData">
                    {{d.service}}
                    <br>
                    ({{d.transitTime}})
                    <a id="dff" *ngIf="(d.service == 'Parcel International Direct Standard' && select && select[0].itemName=='United Arab Emirates')" data-tooltip="Vat charges 5% of the each shipment value." style="position:absolute;right: 90px;top: 1.7rem;"><i  class="fa fa-info-circle mr-2"></i></a>
                    <span class="badge badge-primary badge-pill">
                      {{d.rate  | currency:'INR':'code':'1.2-2'}}</span>
                      </li>
                </ul> -->
                        <!-- {{(select)}} -->



                        <!-- =============== xpressbees rate ============== -->
                        <ul class="list-group" style="margin-top: 30px"
                            *ngIf="xpressbeesRates?.express?.countryRateCombo>0  || xpressbeesRates?.standard?.zoneRateCombo>0">

                            <li class="list-group-item d-flex justify-content-between align-items-center" style="background: #f58220;
                    color: #fff;" readonly
                                *ngIf="xpressbeesRates?.express?.countryRateCombo>0  || xpressbeesRates?.standard?.zoneRateCombo>0">
                                XPRESSBEES
                            </li>

                            <li *ngIf="xpressbeesRates?.express?.countryRateCombo>0"
                                class="list-group-item d-flex justify-content-between align-items-center">
                                Express
                                <br>
                                {{xpressbeesRates?.express?.deliveryTime}}
                                <span class="badge badge-primary badge-pill">
                                    {{xpressbeesRates?.express?.countryRateCombo | currency:'INR':'code':'1.2-2'}}
                                </span>
                            </li>

                            <li *ngIf="xpressbeesRates?.standard?.zoneRateCombo>0"
                                class="list-group-item d-flex justify-content-between align-items-center">
                                Standard
                                <br>
                                {{xpressbeesRates?.standard?.deliveryTime}}
                                <span class="badge badge-primary badge-pill">
                                    {{xpressbeesRates?.standard?.zoneRateCombo | currency:'INR':'code':'1.2-2'}}
                                </span>
                            </li>

                        </ul>
                        <!-- ================================================ -->

                        <span
                            *ngIf="dhlData?.length>0 && ['USA','United Kingdom','Australia'].includes(select[0].itemName)"
                            style="font-style: italic;font-size: 12px;" class="text-danger">Note - </span>
                        <span
                            *ngIf="dhlData?.length>0&& ['USA','United Kingdom','Australia'].includes(select[0].itemName)"
                            style="font-size: 12px;">Prices may vary for this country.</span>


                        <!-- =============== Delhivery rate ============== -->
                        <ul class="list-group" style="margin-top: 30px"
                            *ngIf="showDelhivery && delhiveryRates?.length">

                            <li class="list-group-item d-flex justify-content-between align-items-center" style="background: #ed4136;
                    color: #fff;" readonly >
                                DELHIVERY
                            </li>

                            <!-- <li *ngIf="delhiveryRates"
                                class="list-group-item d-flex justify-content-between align-items-center">
                                Express
                                <span class="badge badge-primary badge-pill">
                                    {{delhiveryRates | currency:'INR':'code':'1.2-2'}}
                                </span>
                            </li> -->

                            <li  *ngFor="let item of delhiveryRates" 
                            class="list-group-item d-flex justify-content-between align-items-center">
                            {{item?.serviceTypeName?.replaceAll("_", " ")}}
                            <br>
                            {{item?.deliveryTime?.replace("BD", " Business days ")}}
                            <span class="badge badge-primary badge-pill">
                                {{ item?.finalRate | currency:'INR':'code':'1.2-2'}}
                            </span>
                        </li>


                        </ul>
                        <!-- ================================================ -->
                        

                        <ul class="list-group" style="margin-top: 30px" *ngIf="aramexRates>0">
                            <li class="list-group-item d-flex justify-content-between align-items-center" style="background: #ff0404;
                      color: #fff;" readonly>
                                ARAMEX RATE
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Express
                                <br>
                                (7-10 Days)
                                <span class="badge badge-primary badge-pill">{{aramexRates |
                                    currency:'INR':'code':'1.2-2'}}</span>
                            </li>
                        </ul>


                        <ul class="list-group" style="margin-top: 30px" *ngIf="fedexRates>0">
                            <li class="list-group-item d-flex justify-content-between align-items-center" style="background: #1a0dab;
                        color: #fff;" readonly>
                                FEDEX RATE
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Express
                                <br>
                                (3-7 Days)
                                <span class="badge badge-primary badge-pill">{{fedexRates |
                                    currency:'INR':'code':'1.2-2'}}</span>
                            </li>
                        </ul>





                        <!-- <div *ngIf="!dataSuccess" class="text-center" style="margin: auto">
                <span style="font-weight: bold">​​​​​​​​​​​​Additional service fee (optional)</span>
                <table class="table">
                  <thead class="pink accent-3">
                    <tr class="text-white">
                      <th></th>
                      <th>​​Air Service</th>
                      <th>​​EMS Speed Post </th>
                      <th>International Air Parcel</th>
                      <th>Tracked Packets</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Advice of Delivery Charges</th>
                      <th>₹ 10</th>
                      <th>​NA</th>
                      <td>₹ 10</td>
                      <td>NA</td>
                    </tr>
                    <tr>
                      <th>Delivery in person Charges</th>
                      <th>NA</th>
                      <th>₹​ 10</th>
                      <td>₹ 5</td>
                      <td>NA</td>
                    </tr>
                    <tr>
                      <th>Door Delivery Charges</th>
                      <th>NA</th>
                      <th>​NA</th>
                      <td>₹ 20</td>
                      <td>NA</td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document"
        style=" max-width: 650px !important;background: white !important; box-shadow: -3px 5px 20px 2px #211e1ed1 !important;">

        <table class="table text-center">
            <thead class="mdb-color darken-3">
                <tr class="text-white">
                    <th>​​Monthly International EMS Revenue</th>
                    <th>Discount Rate</th>
                    <th class="text-center">Allowed EMS destinations</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>₹ 2,00,000/- to 10,00,000/-​</th>
                    <td>5%</td>
                    <td>All EMS destinations served by India Post</td>
                </tr>
                <tr>
                    <th>₹ 10,00,001/- to 50,00,000/-</th>
                    <td>10%</td>
                    <td>-Do-</td>
                </tr>
                <tr>
                    <th>Above ₹ 50,00,000/-​</th>
                    <td>15%</td>
                    <td>-Do-</td>
                </tr>
                <tr>
                    <td colspan="3">
                        <button class="btn btn-sm btn-danger">
                            <i class="fa fa-times"></i>&nbsp; Close
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</div>